.wholepage {
    margin-right: auto;
    margin-left: auto;
    width: 100%;
}

.container-head {
    /* border: 1px solid rgb(203, 201, 201); */
    height: 350px;
    width: 98%;
    max-width: max-content;
    border-radius: 10px;
    padding-top: 10px;
    margin-right: auto;
    margin-left: auto;
}

.container1 {
    /* border: 1px solid black; */
    /* height: 351px; */
    height: 100%;
    /* height: max-content; */
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    overflow: hidden;
}

.video12 {
    width: 90%;
    margin: 5%;
    height: 89%;
    /* width: 100%; */
    background:
        linear-gradient(to right, #48283f 4px, transparent 4px) 0 0,
        linear-gradient(to right, #48283f 4px, transparent 4px) 0 100%,
        linear-gradient(to left, #48283f 4px, transparent 4px) 100% 0,
        linear-gradient(to left, #48283f 4px, transparent 4px) 100% 100%,
        linear-gradient(to bottom, #48283f 4px, transparent 4px) 0 0,
        linear-gradient(to bottom, #48283f 4px, transparent 4px) 100% 0,
        linear-gradient(to top, #48283f 4px, transparent 4px) 0 100%,
        linear-gradient(to top, #48283f 4px, transparent 4px) 100% 100%;
    padding: 10px;
    background-repeat: no-repeat;
    background-size: 20px 20px;

}

.video23 {
    border: 1px solid red;
    display: none;
}

.scan-line {
    width: 88%;
    height: 4px;
    background-color: #48283f;
    position: relative;
    animation: mymove 1s infinite;
    margin-left: 6%;
}

@keyframes mymove {
    from {
        top: 10%;
    }

    to {
        top: 90%
    }
}

.image {
    position: relative;
    left: 0px;
    top: -382px;
    /* top: -404px; */
    width: 100%;
    /* max-width: max-content; */
    height: 310px;
    transition: 0.1ms;
}

.button-head1 {
    /* border: 1px solid red; */
    text-align: center;
    width: 100%;
}

.click {
    height: 30px;
    border: 1px solid black;
    width: 100%;
    position: relative;
    top: 14px;
    /* top: -197px; */
    text-align: center;
    background-color: rgb(110, 18, 108);
    color: white;
    /* left: 10px; */
    cursor: pointer;
    border-radius: 5px;
}

.click:hover {
    color: white;
    background-color: rgb(110, 18, 108);

}

.close-button {
    height: 30px;
    border: 1px solid black;
    width: 100%;
    position: relative;
    top: 23px;
    /* top: -186px; */
    /* left: 150px; */
    text-align: center;
    cursor: pointer;
    background-color: rgb(110, 18, 108);
    color: white;
    border-radius: 5px;
}

.close-button:hover {
    color: white;
    background-color: rgb(110, 18, 108);
}


.upload {
    height: 30px;
    border: 1px solid black;
    width: 100%;
    position: relative;
    /* margin-top: -40%; */
    top: -277px;
    /* left: 9px; */
    text-align: center;
    cursor: pointer;
    background-color: rgb(110, 18, 108);
    color: white;
    border-radius: 5px;
}

.upload:hover {
    color: white;
    background-color: rgb(110, 18, 108);
}

.headd {
    width: 100%;
    height: 40px;
    margin-top: 10px;
}

.head {
    margin-top: 10px;
    height: 40px;
    width: 60%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: auto;
}

.head1 {
    color: black;
    font-size: larger;
    font-weight: 500;
    border: "none";
    cursor: pointer;

}

.head2 {
    color: black;
    font-size: larger;
    font-weight: 500;
    border: "none";
    cursor: pointer;

}

.process {
    text-align: center;
}

.displayimage {
    height: 300px;
    width: 250px;
    border: 1px solid black;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    border-radius: 20px;
}

.displayimage2 {
    height: 300px;
    width: 250px;
    border-radius: 20px;
}

.switchCamera {
    margin-right: 48%;
}
.spinner-border{
    position:absolute;
    top:40%;
}