
  
@import url("https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css");
.picsize{
    height:80px;
    width:100px;
  }
  .button-head{
    height: 40px;
    width: 20%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 20px;
    margin: auto;
  }
  .back{
    color: black;
    font-size: larger;
    font-weight: 500;
    border: "none";
    cursor: pointer;
  }
  .logout{
    padding-left: 15px;
  }
  .tr{
    margin-top: 20px;
  }
  
