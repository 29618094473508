.box{
    height: 206px;
    width: max-content;
    border-radius: 31px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    border: 1px solid rgb(161, 159, 159);
    background-color: white;
    text-align: center;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    
}

.heading{
    height: 30px;
    width: 100%;
    color: red;
    font-size: larger;
    font-weight: 500;
    text-align: center;
    border-radius: 10px;
    margin-top: 34px;
}

.number-box{
/* margin-left: 68px; */
height:40px;
width:100%;
border-radius: 10px;

}
.number-box input{
    border-radius: 10px;
    
    }
.number-box1{
    border-radius: 13px;
    height: 30px;
    text-align: center;
    background-color: rgb(222, 222, 223);
    border:none;
    }

#sub{
margin-left: 80px;
margin-top: 20px;
color: red;
border: none;
font-size: larger;
font-weight: 500;
text-align: center;
border-radius: 5px;

}
#sub:hover{
    background-color: rgb(247, 68, 68);
    color:white;
}
.button{
background-color:#723477 ;
color:white;
/* margin-left: 100px; */
border-radius: 10px;
border:none;
margin-top: 10px;
}