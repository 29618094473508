.success-image {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.bac{
    background-image: url("./success-image.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
}
.one {
    height: 100px;
    width: 250px;
    text-align: center;
    margin-top: -0px;
    margin-left: auto;
    margin-right: auto;
}

.logo-size {

    height: 90px;
    width: 250px;
}

.two {
    height: 280px;
    width: 350px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 15px;
    margin-top: 20px;
}

.success-image-inside {
    height: 280px;
    width: 350px;
    border-radius: 15px;
}

.three {
    height: 100px;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    text-align: center;
}

.four {
    width: 250px;
    margin-left: auto;
    margin-right: auto;
}

.five {
    cursor: pointer;
    margin-left: 206px;
    margin-top: -31px;
}
.six {
    height: 150px;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2px;
    text-align: center;
}
.au{
    color:white;
    font-weight: 500;
    font-size: large;

}
.auscore{
    min-height:20px;
    width:40px;
    color: white;
}
.goback {
    font-size: larger;
    font-weight: 500;
    cursor: pointer;
}

.su {
    font-weight: 700;
    color: rgb(234, 228, 228);
    font-size: xx-large;
}

.ss {
    color: white;
    font-size: xx-large;
    font-weight: 700;
    word-spacing: 5px;
}

.sk {
       color: white;
    font-size:x-large;
    font-weight: 700;
    word-spacing: 5px;
}

.image-size {
    height: 100vh;
    width: 100%;

}

.bac {
    height: 100vh;
    width: 100%;
    border: 1px solid black;
}

.image-size {
    height: 100%;
    width: 100%;
}