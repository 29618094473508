.start-back{
    background-color: rgb(110, 18, 108);
    height:100vh;
    width:100%;
    display: flex;
    flex-direction: column;
    align-self: center;
}
.startlogo{
   
    margin:auto;

}
.logosize{
    height:300px;
    width:100%;
    margin:auto;
}