/* ul#loadbar {
    list-style: none;
    width: 140px;
    margin: 0 auto;
    padding-top: 50px;
    padding-bottom: 75px;
}

ul#loadbar li {
    float: left;
    position: relative;
    width: 11px;
    height: 26px;
    margin-left: 1px;
    border-left: 1px solid #111;
    border-top: 1px solid #111;
    border-right: 1px solid #333;
    border-bottom: 1px solid #333;
    background: #000;
}

ul#loadbar li:first-child {
    margin-left: 0;
}

.bar {
    background-color: #2187e7;
    background-image: -moz-linear-gradient(45deg, #2187e7 25%, #a0eaff);
    background-image: -webkit-linear-gradient(45deg, #2187e7 25%, #a0eaff);
    width: 11px;
    height: 26px;
    opacity: 0;
    -webkit-animation: fill .5s linear forwards;
    -moz-animation: fill .5s linear forwards;
}

#layerFill1 {
    -moz-animation-delay: 0.5s;
    -webkit-animation-delay: 0.5s;
}

#layerFill2 {
    -moz-animation-delay: 1s;
    -webkit-animation-delay: 1s;
}

#layerFill3 {
    -moz-animation-delay: 1.5s;
    -webkit-animation-delay: 1.5s;
}

#layerFill4 {
    -moz-animation-delay: 2s;
    -webkit-animation-delay: 2s;
}

#layerFill5 {
    -moz-animation-delay: 2.5s;
    -webkit-animation-delay: 2.5s;
}

#layerFill6 {
    -moz-animation-delay: 3s;
    -webkit-animation-delay: 3s;
}

#layerFill7 {
    -moz-animation-delay: 3.5s;
    -webkit-animation-delay: 3.5s;
}

#layerFill8 {
    -moz-animation-delay: 4s;
    -webkit-animation-delay: 4s;
}

#layerFill9 {
    -moz-animation-delay: 4.5s;
    -webkit-animation-delay: 4.5s;
}

#layerFill10 {
    -moz-animation-delay: 5s;
    -webkit-animation-delay: 5s;
}

@-moz-keyframes fill {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    };
}

@-webkit-keyframes fill {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    };
} */



.outerbox{
    height: 80px;
    width: 300px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
}
.loader {
    width: 250px;
    margin: 150px auto 70px;
    margin: auto;
}

.loader .loading_1 {
    position: relative;
    width: 100%;
    height: 30px;
    border: 1px solid yellowgreen;
    border-radius: 10px;
    animation: turn 4s linear 1.75s infinite;
    
    
}

.loader .loading_1:before {
    content: "";
    display: block;
    position: absolute;
    width: 0;
    height: 100%;
    background-color: rgb(219, 198, 35);
    box-shadow: 10px 0px 15px 0px rgb(189, 213, 140);
    animation: load 60s linear infinite;
}

.loader .loading_2 {
    color: black;
    font-size: 22px;
    text-align: center;
    animation: bounce 2s linear infinite;
}

@keyframes load {
    0% {
        width: 0%;
    }

    87.5% {
        width: 100%;
    }
}

 /* @keyframes turn {
    0% {
        transform: rotateY(0deg);
    }

    6.25%,
    50% {
        transform: rotateY(180deg);
    }

    56.25%,
    75% {
        transform: rotateY(360deg);
    }
}

@keyframes bounce {

    0%,
    100% {
        top: 10px;
    }

    12.5% {
        top: 30px;
    }
}  */