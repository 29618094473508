.gold-scanner {
  height: 300px;
  width: 300px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1%;

}

.gold-image {
  height: 97.5%;
  width: 97.5%;
  margin-top: 0.8%;
  margin-left: 1%;
  border: 2px solid black;
  border-radius: 5px;
}

/* .scan-line {
  width: 100%;
  height: 4px;
  background-color: rgb(219, 198, 35);
  position: relative;
  animation: mymove 1s infinite;
}

@keyframes mymove {
  from {
    top: 0px;
  }

  to {
    top: 100%
  }
} */

.swal-button:not([disabled]):hover {
  background-color: purple;
}

.swal-button {
  background-color: purple;
  color: #fff;
  border: none;
  box-shadow: none;
  border-radius: 5px;
  font-weight: 600;
  font-size: 14px;
  padding: 10px 24px;
  margin: 0;
  cursor: pointer;
}

.flex {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 30px;
  width: 50%;
  margin-top: 1%;
  margin-left: auto;
  margin-right: auto;
}

.flex1 {

  width: 80px;
  font-size: larger;
  font-weight: 500;
  cursor: pointer;
}

.flex2 {

  width: 80px;
  color: black;
}