
@import url("https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-alpha1/dist/css/bootstrap.min.css");
/* @import url("https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-alpha1/dist/js/bootstrap.bundle.min.js"); */
/* @import url("https://cdnjs.cloudflare.com/ajax/libs/jquery/3.2.1/jquery.min.js"); */

.card {
    width: 350px;
    padding: 30px;
    border-radius: 20px;
    background: #fff;
    border: none;
    min-height: 350px;
    position: relative;
    border: 1px solid rgb(198, 195, 195);
    margin-top: 60px;
    margin-right: auto;
    margin-left: auto;
  }
  .card:hover{
    box-shadow: 0 0 5px #424141
  }
  
  .container {
    height: 60vh;
  }
  
  .mobile-text {
    color: black;
    font-size: 15px;
  }
  
 input .form-control {
    margin-right: 12px;
  }
  
  .form-control:focus {
    color:black;
    background-color: white;
    border-color: black;
    outline: 0;
    box-shadow: none;
  }
  
  .cursor {
    cursor: pointer;
  }
  .cursor:hover {
    cursor: pointer;
    
  }
  .submit{
    color: #470249;
    border: none;
    background-color: white;
  }
  .resend{
    background-color: #470249;
    color:white;
    font-weight:500;
    border:none;
    font-size:larger;
    border-radius: 5px;

  }
  element.style {
    color: #470249;
    font-weight: 500;
    border: none;
    font-size: larger;
  }