.logo-background{
    display: flex;
    flex-direction: row;
    height:100px;
    width:100%;
    background-color: rgb(110, 18, 108);
    justify-content: center;
} 
.logo{
margin:auto;
}   
.size{
    margin: auto;
}

